import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageHttpService } from '../language-http/language-http.service';
import { LanguageDto } from '../../dtos/language/language.dto';
import { LanguageModel } from '../../models/language/language.model';
import { LanguageCreator } from '../../creators/language/language.creator';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { ListModel } from '../../../shared/models/list/list.model';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { LanguageDictionaryDataPageDto } from '../../dtos/language-dictionary-data-page/language-dictionary-data-page.dto';
import { LanguageDictionaryDataPageModel } from '../../models/language-dictionary-data-page/language-dictionary-data-page.model';
import { LanguageDictionaryDataPageDtoCreator } from '../../creators/language-dictionary-data-page-dto/language-dictionary-data-page-dto.creator';
import { LanguageListDataPageModel } from '../../models/language-list-data-page/language-list-data-page.model';
import { LanguageListDataPageDto } from '../../dtos/language-list-data-page/language-list-data-page.dto';
import { LanguageListDataPageDtoCreator } from '../../creators/language-list-data-page-dto/language-list-data-page-dto.creator';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private languageHttpService: LanguageHttpService) {}

  public getLanguageList(languageListDataPage: LanguageListDataPageModel): Observable<ListModel<LanguageModel>> {
    const languageListDataPageDto: LanguageListDataPageDto = LanguageListDataPageDtoCreator.create(languageListDataPage);

    return this.languageHttpService.getLanguageList(languageListDataPageDto).pipe(
      map((languageListDto: ListDto<LanguageDto>) => {
        const items: Array<LanguageModel> = languageListDto.items.map((dto: LanguageDto) => LanguageCreator.create(dto));

        return ListCreator.create<LanguageDto, LanguageModel>(languageListDto, items);
      })
    );
  }

  public getLanguageDictionary(languageDictionaryDataPage: LanguageDictionaryDataPageModel): Observable<ListModel<LanguageModel>> {
    const languageDictionaryDataPageDto: LanguageDictionaryDataPageDto =
      LanguageDictionaryDataPageDtoCreator.create(languageDictionaryDataPage);

    return this.languageHttpService.getLanguageDictionary(languageDictionaryDataPageDto).pipe(
      map((languageListDto: ListDto<LanguageDto>) => {
        const items: Array<LanguageModel> = languageListDto.items.map((dto: LanguageDto) => LanguageCreator.create(dto));

        return ListCreator.create<LanguageDto, LanguageModel>(languageListDto, items);
      })
    );
  }
}
