import { LanguageDictionaryDataPageDto } from '../../dtos/language-dictionary-data-page/language-dictionary-data-page.dto';
import { LanguageDictionaryDataPageModel } from '../../models/language-dictionary-data-page/language-dictionary-data-page.model';

export class LanguageDictionaryDataPageDtoCreator {
  public static create(model: LanguageDictionaryDataPageModel): LanguageDictionaryDataPageDto {
    return {
      ...(model.page && { page: model.page }),
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
    };
  }
}
