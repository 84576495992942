import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { LanguageDto } from '../../dtos/language/language.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { QueryParamsUtil } from '../../../shared/utils/query-params/query-params.util';
import { LanguageDictionaryDataPageDto } from '../../dtos/language-dictionary-data-page/language-dictionary-data-page.dto';
import { LanguageListDataPageDto } from '../../dtos/language-list-data-page/language-list-data-page.dto';

@Injectable({
  providedIn: 'root',
})
export class LanguageHttpService {
  constructor(private httpClient: HttpClient) {}

  public getLanguageList(languageListDataPage: LanguageListDataPageDto): Observable<ListDto<LanguageDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<LanguageDictionaryDataPageDto>(languageListDataPage);

    return this.httpClient
      .get<ApiResponseDto<ListDto<LanguageDto>>>(ApiUrlUtil.getApiUrl('language/language'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<LanguageDto>>) => response.data));
  }

  public getLanguageDictionary(languageDictionaryDataPage: LanguageDictionaryDataPageDto): Observable<ListDto<LanguageDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<LanguageDictionaryDataPageDto>(languageDictionaryDataPage);

    return this.httpClient
      .get<ApiResponseDto<ListDto<LanguageDto>>>(ApiUrlUtil.getApiUrl('language/dictionary'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<LanguageDto>>) => response.data));
  }
}
