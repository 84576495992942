import { LanguageListDataPageDto } from '../../dtos/language-list-data-page/language-list-data-page.dto';
import { LanguageListDataPageModel } from '../../models/language-list-data-page/language-list-data-page.model';

export class LanguageListDataPageDtoCreator {
  public static create(model: LanguageListDataPageModel): LanguageListDataPageDto {
    return {
      ...(model.page && { page: model.page }),
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
    };
  }
}
